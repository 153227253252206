// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/sstephenson/sprockets#sprockets-directives) for details
// about supported directives.
//

import jQuery from "jquery";
import "@assets/javascripts/cable.jsx";

Object.assign(window, { jQuery, $: jQuery });

const extra_info =
  " Please provide a brief description for this change in CD Status.";
let confirm_message = "";
$(document).ready(function () {
  $("#cd_toggle").change(function () {
    if (this.checked) {
      confirm_message = "Enable CD?";
    } else {
      confirm_message = "Disable CD?";
    }
    let reason = prompt(confirm_message + extra_info, "");
    if (reason == null) {
      $(this).prop("checked", !this.checked);
    } else {
      reason = $.trim(reason);
      if (reason) {
        $("input[name='cd_reason'").val(reason);
        $("#cd_form").submit();
      } else {
        if (reason == "") {
          alert(
            "There was no reason was given for CD Status change. Not changing CD status."
          );
        }
        $(this).prop("checked", !this.checked);
      }
    }
  });

  $(".prod-cd-btn").click(function (e) {
    const targetCdState = $(this).data("targetCdState");
    if ($(this).data("selector") == "prod") {
      confirm_message =
        targetCdState + " CD for 'production' and 'production-us02'?";
    } else {
      confirm_message =
        targetCdState +
        " CD for 'sandbox', 'rnd_sbx-m-a', 'monthly-sandbox-us02'?";
    }

    let reason = prompt(
      confirm_message,
      "CD status updated to '" + targetCdState + "' from admin panel."
    );
    if (reason == null) {
      e.preventDefault();
    } else if ($.trim(reason)) {
      $("input[name='prod_cd_reason'").val(reason);
      $("#disable_prod_cd").submit();
    } else {
      alert(
        "There was no reason was given for CD Status change. Not changing CD status."
      );
      e.preventDefault();
    }
  });
});
